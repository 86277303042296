@font-face {
    font-family: "conthrax";
    src: url('assets/conthrax-sb.ttf');
}
body, input {
    font-family: conthrax, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}
body {
    margin: 0;
    padding: 0;
    font-family: conthrax, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    background-color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('assets/background.svg');
    background-size: cover;
}

* {
    box-sizing: border-box;
}
